import { Theme } from '@material-ui/core/styles/createMuiTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { colors, fontWeight, NoahFont, RiftFont } from '../../assets';

type Props = {
  disableActions: boolean;
  disabled: boolean;
};

export const useStyles = makeStyles<Theme, Props>(() => ({
  root: {
    position: 'relative',
  },
  editorWrapper: {
    overflow: 'hidden',

    '&:after': {
      content: '',
      whiteSpace: 'pre-wrap',
      userSelect: 'none',
      display: 'inline',
    },
    '& .sun-editor': {
      backgroundColor: colors.grey20,
      borderRadius: 4,
      border: '1px solid rgba(255, 255, 255, 0)',
    },

    '& .sun-editor .se-toolbar, & .sun-editor .se-resizing-bar': {
      outline: 'none',
      backgroundColor: 'initial',
      minHeight: 8,
      border: 'none',
    },

    '& .se-btn-tray': {
      padding: '8px 8px 4px',
      opacity: ({ disableActions, disabled }) => (disableActions && !disabled ? 0.5 : 1),
      pointerEvents: ({ disableActions }) => (disableActions ? 'none' : 'initial '),
    },

    '& .se-toolbar-cover': {
      backgroundColor: colors.grey20,
    },

    '& .toolbar-icon': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& svg': {
        width: 24,
        height: 24,
      },
    },

    '& .se-toolbar-separator-vertical': {
      margin: 8,
    },

    '& .se-btn-module': {
      backgroundColor: colors.grey10,
    },

    '& .se-btn-module-border': {
      borderColor: colors.grey30,
    },

    '& .se-btn': {
      width: 36,
      height: 36,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '&.active': {
        backgroundColor: colors.grey40,
      },

      '&.se-btn-select': {
        backgroundColor: colors.white,
        width: 'auto',
        minWidth: 88,

        '&[data-command="formatBlock"]': {
          minWidth: 146,
        },

        '& .txt': {
          marginRight: 32,
          fontSize: 16,
          fontFamily: NoahFont,
          fontWeight: fontWeight.bold,
          color: colors.grey140,
        },
      },
    },

    '& .se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable': {
      borderRadius: 4,
      backgroundColor: colors.white,
      border: `1px solid ${colors.grey30}`,
    },

    '& .se-wrapper': {
      padding: '0 8px',

      '& .se-placeholder': {
        fontSize: 16,
        fontFamily: NoahFont,
        padding: '17px 25px',
      },
    },

    '& .se-submenu.se-list-layer': {
      boxShadow:
        '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
      border: 'none',
    },

    '& .sun-editor .se-list-inner .se-list-basic li button': {
      border: 'none',
      color: colors.basic.black,

      '&:hover': {
        border: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },

      '&.active': {
        border: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
    },

    '& .mention': {
      cursor: 'pointer',
    },

    '& .se-dialog-header': {
      borderBottom: 'none !important',
    },

    '& .se-modal-title': {
      fontFamily: RiftFont,
      fontSize: '24px !important',
      lineHeight: '1.5 !important',
      color: colors.basic.black,
    },

    '& .se-dialog-body': {
      paddingTop: '5px !important',
    },

    '& ._se_bookmark_button': {
      display: 'none',
      opacity: 0,
      visibility: 'hidden',
    },

    '& .se-dialog-footer': {
      borderTop: 'none !important',
    },

    '& .se-dialog-footer .se-btn-primary': {
      backgroundColor: colors.primary.main,
      color: colors.basic.white,
      border: 'none',
      textTransform: 'uppercase',
      fontFamily: RiftFont,
      fontWeight: '700 !important',
      fontSize: 16,
    },

    '& .se-dialog-form label': {
      color: colors.grey120,
      fontSize: 12,
    },

    '& .se-dialog-form-footer label': {
      display: 'block !important',
      color: colors.basic.black,
      padding: '8px 0',
      lineHeight: 1,

      '& input': {
        transform: 'scale(1.5)',
        marginRight: '8px !important',
        marginLeft: '3px !important',
        marginBottom: '3px !important',
      },
    },

    '& .se-dialog-form-footer label:has(> input._se_anchor_download)': {
      display: 'none !important',
      opacity: '0 !important',
      visibility: 'hidden !important',
    },
  },
  error: {
    '& .sun-editor': {
      borderColor: colors.functionals.alert,
    },
  },
  preMention: {
    color: colors.functionals.infoDark,
    cusrsor: 'none',
    pointerEvents: 'none',
    userSelect: 'none',
  },
  mention: {
    color: colors.grey160,
    backgroundColor: colors.grey30,
    padding: '0px 4px!important',
    borderRadius: 20,
    border: `1px solid ${colors.grey30}`,
    cursor: 'pointer',
  },
  mentionContentWrap: {
    display: 'inline',
    overflowWrap: 'break-word',
    pointerEvents: 'auto',
    textDecorationSkipInk: 'auto',
    whiteSpace: 'nowrap',
    WebkitUserModify: 'read-only',
  },
  inlineNodeViewAddZeroWidthSpace: {
    display: 'inline',
    overflowWrap: 'break-word',
    userSelect: 'none',
    whiteSpace: 'pre-wrap',
    '&:after': {
      content: '""',
      overflowWrap: 'break-word',
      pointerEvents: 'auto',
      userSelect: 'none',
      whiteSpace: 'pre-wrap',
    },
  },
  zeroWidthSpaceContainer: {
    overflowWrap: 'break-word',
    pointerEvents: 'auto',
    textDecorationSkipInk: 'auto',
    userSelect: 'none',
    whiteSpace: 'nowrap',
  },
}));
