import { createContext, FC, memo, useMemo, useState } from 'react';

import { ISplitFactoryChildProps, SplitFactoryProvider } from '@splitsoftware/splitio-react';

import { Loader } from 'components/loader/Loader';

import { SplitContextDispatch, SplitContextControllerProps } from './SplitContextController.types';

export const SplitDispatchContext = createContext<SplitContextDispatch | undefined>(undefined);

export const SplitContextController: FC<SplitContextControllerProps> = memo(
  ({ attributes: defaultAttributes, children, customerKey, sdkKey }) => {
    const config: SplitIO.IBrowserSettings = useMemo(
      () => ({
        core: {
          authorizationKey: sdkKey,
          key: customerKey,
        },
      }),
      [customerKey, sdkKey],
    );

    const [attributes, setAttributes] = useState(defaultAttributes);

    return (
      <SplitDispatchContext.Provider value={setAttributes}>
        <SplitFactoryProvider config={config} updateOnSdkTimedout={true} attributes={attributes}>
          {({ factory, isReady, isTimedout, lastUpdate }: ISplitFactoryChildProps) => {
            if (!isReady) return <Loader />;

            return children;
          }}
        </SplitFactoryProvider>
      </SplitDispatchContext.Provider>
    );
  },
);
