import { ChangeEvent, forwardRef, memo, useCallback } from 'react';

import clsx from 'clsx';

import { useStyles } from './ImagePluginInput.styles';
import { ImagePluginInputProps } from './ImagePluginInput.types';

export const ImagePluginInput = memo(
  forwardRef<HTMLInputElement, ImagePluginInputProps>(({ onChange }, ref) => {
    const styles = useStyles();

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
          onChange(e.target.files);
          e.target.value = '';
        }
      },
      [onChange],
    );

    return (
      <div>
        <input
          ref={ref}
          type="file"
          accept="image/*"
          multiple
          id="images_upload"
          className={clsx(styles.input, 'files-text files-input')}
          onChange={handleChange}
        />
      </div>
    );
  }),
);
