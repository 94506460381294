import { colors } from "@chhjit/react-components";
import { SvgIcon } from "@material-ui/core";

export const ArrowBackIcon = ({
  className,
  color = colors.primary.main,
  fontSize = 24,
}: {
  className?: string;
  color?: string;
  fontSize?: number;
}) => (
  <SvgIcon
    className={className}
    style={{ fontSize }}
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
  >
    <path
      d="M6.50079 1.61169e-05C6.36918 -0.000748707 6.23872 0.0246019 6.11688 0.0746145C5.99505 0.124627 5.88423 0.198319 5.79079 0.291463L0.790791 5.31639C0.60454 5.50469 0.499999 5.7594 0.499999 6.0249C0.499999 6.29041 0.604541 6.54512 0.790792 6.73342L5.79079 11.7583C5.98209 11.923 6.22817 12.009 6.47985 11.9992C6.73152 11.9895 6.97026 11.8846 7.14836 11.7056C7.32645 11.5267 7.43078 11.2867 7.4405 11.0338C7.45023 10.7809 7.36462 10.5336 7.20079 10.3413L2.91079 6.02993L7.20079 1.71854C7.34121 1.57857 7.43718 1.39991 7.47657 1.20513C7.51595 1.01035 7.49698 0.808204 7.42206 0.62426C7.34713 0.440314 7.21962 0.282828 7.05563 0.171715C6.89165 0.0606001 6.69856 0.000849594 6.50079 1.61169e-05Z"
      fill={color}
    />
  </SvgIcon>
);
