import { useCallback, useRef } from 'react';

import { TextEditorProps } from '../TextEditor.types';

type Props = Pick<TextEditorProps, 'getInstance'>;

export const useInstance = ({ getInstance }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const suneditor = useRef<any>();

  const getSunEditorInstance = useCallback(
    sunEditor => {
      suneditor.current = sunEditor;

      if (getInstance) {
        getInstance(sunEditor);
      }
    },
    [getInstance],
  );

  return { suneditor, getSunEditorInstance };
};
