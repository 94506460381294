import { MutableRefObject, useCallback, useRef, useState } from 'react';

import { TextEditorProps, MentionUser } from '../TextEditor.types';
import { MentionDropdown } from '../ui/mentionDropdown/MentionDropdown';
import { colors } from 'assets';

type Props = NonNullable<TextEditorProps['mentionProps']> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  suneditor: MutableRefObject<any>;
  disabled?: boolean;
};

export const useMention = ({ suneditor, users, mentionesId, disabled, add, remove }: Props) => {
  const mentionedIds = useRef<number[]>(mentionesId ?? []);
  const isPremention = useRef(false);
  const preMentionSelection = useRef<HTMLElement | null>(null);
  const lastSelection = useRef<null | HTMLElement>(null);
  const showDropdownRef = useRef(false);

  const [showDropdown, setShowDropdown] = useState(false);
  const [isMentionSelected, setIsMentionSelected] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState<MentionUser[]>(
    users.sort((a, b) => a.fullName.localeCompare(b.fullName)),
  );

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (disabled || !suneditor.current) {
        return;
      }

      const selection = suneditor.current.core.getSelection();

      if (e.key === '@') {
        isPremention.current = true;
        const newNode: HTMLElement = suneditor.current.core.util.createElement('SPAN');
        newNode.ariaHidden = 'true';
        newNode.style.color = colors.functionals.infoDark;
        newNode.style.cursor = 'none';
        newNode.style.pointerEvents = 'none';
        newNode.style.userSelect = 'none';
        newNode.className = 'pre-mention';
        newNode.innerHTML = '&#8203;';

        const preMentionedNode = suneditor.current.core.insertNode(newNode);
        preMentionSelection.current = preMentionedNode;

        setShowDropdown(true);
        showDropdownRef.current = true;
      }

      if (showDropdownRef.current && e.code === 'Space') {
        setShowDropdown(false);
        showDropdownRef.current = false;
      }

      if (selection.focusNode?.parentNode?.className.split(' ').includes('pre-mention') && e.code === 'ArrowUp') {
        if (preMentionSelection.current) {
          suneditor.current.util.removeItem(preMentionSelection.current);
          const selectionNode = suneditor.current.core.getSelectionNode();

          suneditor.current.core.setRange(selectionNode, selectionNode.length, selectionNode, selectionNode.length);

          const newNode: HTMLElement = suneditor.current.core.util.createElement('SPAN');
          newNode.innerHTML = preMentionSelection.current.innerText;
          const insertedNode = suneditor.current.core.insertNode(newNode);

          suneditor.current.core.setRange(insertedNode, insertedNode.length, insertedNode, insertedNode.length);

          preMentionSelection.current = null;
          isPremention.current = false;
          setShowDropdown(false);
        }
      }

      if (
        selection?.focusNode?.parentNode &&
        selection.focusNode?.parentNode.className.split(' ').includes('mention')
      ) {
        suneditor.current.util.removeItem(selection.focusNode.parentNode.parentNode.parentNode.parentNode);
        suneditor.current.core.nodeChange(null, null, ['SPAN'], false);

        setIsMentionSelected(false);
      }
    },
    [suneditor, disabled],
  );

  const onKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (disabled || !suneditor.current) {
        return;
      }

      const selection = suneditor.current.core.getSelection();

      if (
        selection?.focusNode?.parentNode &&
        selection.focusNode?.parentNode?.className.split(' ').includes('pre-mention')
      ) {
        const text = (selection.focusNode.data as string).trim().replace('@', '').toLowerCase();

        setFilteredUsers(
          users
            .filter(item => item.fullName.toLowerCase().includes(text))
            .sort((a, b) => a.fullName.localeCompare(b.fullName)),
        );
      }

      if (
        (selection?.focusNode?.parentNode &&
          !selection.focusNode?.parentNode.className.split(' ').includes('pre-mention') &&
          isPremention.current) ||
        (isPremention.current && e.code === 'Space')
      ) {
        if (preMentionSelection.current) {
          suneditor.current.util.removeItem(preMentionSelection.current);
          const selectionNode = suneditor.current.core.getSelectionNode();

          if (e.code !== 'ArrowLeft' && e.code !== 'Backspace') {
            suneditor.current.core.setRange(
              selectionNode,
              selectionNode.length - 1,
              selectionNode,
              selectionNode.length - 1,
            );
          }

          const newNode: HTMLElement = suneditor.current.core.util.createElement('SPAN');
          newNode.innerHTML = preMentionSelection.current.innerText;

          const insertedNode = suneditor.current.core.insertNode(newNode);

          if (e.code === 'ArrowLeft') {
            suneditor.current.core.setRange(insertedNode, 0, insertedNode, 0);
          }

          preMentionSelection.current = null;
          isPremention.current = false;
          setShowDropdown(false);
        }
      }

      suneditor.current.onInput(
        new InputEvent('input', {
          bubbles: true,
          data: Array.from(document.getElementsByClassName('se-wrapper-wysiwyg'))[0].innerHTML,
        }),
      );
    },
    [suneditor, users, disabled],
  );

  const handleSelectUser = useCallback(
    (user: MentionUser) => {
      if (disabled || !suneditor.current) {
        return;
      }

      const selectionNode = suneditor.current.core.getSelectionNode();
      suneditor.current.util.removeItem(selectionNode);
      suneditor.current.core.nodeChange(null, null, ['SPAN'], false);

      suneditor.current.core.nodeChange(null, null, null, false);

      const mentionContentWrap: HTMLElement = suneditor.current.core.util.createElement('SPAN');
      mentionContentWrap.contentEditable = 'false';
      mentionContentWrap.className = 'mentionContentWrap';

      const zeroWidthSpaceContainer: HTMLElement = suneditor.current.core.util.createElement('SPAN');
      zeroWidthSpaceContainer.ariaHidden = 'true';
      zeroWidthSpaceContainer.contentEditable = 'false';
      zeroWidthSpaceContainer.className = 'zeroWidthSpaceContainer';

      const inlineNodeViewAddZeroWidthSpace: HTMLElement = suneditor.current.core.util.createElement('SPAN');
      inlineNodeViewAddZeroWidthSpace.contentEditable = 'false';
      inlineNodeViewAddZeroWidthSpace.className = 'inlineNodeViewAddZeroWidthSpace';

      zeroWidthSpaceContainer.appendChild(inlineNodeViewAddZeroWidthSpace);
      zeroWidthSpaceContainer.appendChild(document.createRange().createContextualFragment('&#8203;'));

      const mentionWrap: HTMLElement = suneditor.current.core.util.createElement('SPAN');
      mentionWrap.ariaHidden = 'true';

      const clearSpan: HTMLElement = suneditor.current.core.util.createElement('SPAN');

      const mention: HTMLElement = suneditor.current.core.util.createElement('SPAN');
      mention.id = `mention-${user.id}`;
      mention.style.color = colors.grey160;
      mention.style.fontWeight = 'bold';
      mention.style.backgroundColor = colors.grey10;
      mention.style.padding = '0px 8px';
      mention.style.borderRadius = '60px';
      mention.className = 'mention';
      mention.innerHTML = `${user.fullName}`;

      clearSpan.appendChild(mention);
      mentionWrap.appendChild(clearSpan);

      const inlineNodeViewAddZeroWidthSpace2: HTMLElement = suneditor.current.core.util.createElement('SPAN');
      inlineNodeViewAddZeroWidthSpace2.contentEditable = 'false';
      inlineNodeViewAddZeroWidthSpace2.className = 'inlineNodeViewAddZeroWidthSpace';

      inlineNodeViewAddZeroWidthSpace2.appendChild(document.createRange().createContextualFragment('&#8203;'));

      const zeroWidthSpaceContainer2: HTMLElement = suneditor.current.core.util.createElement('SPAN');
      zeroWidthSpaceContainer2.ariaHidden = 'true';
      zeroWidthSpaceContainer2.contentEditable = 'false';
      zeroWidthSpaceContainer2.className = 'zeroWidthSpaceContainer';
      zeroWidthSpaceContainer2.appendChild(inlineNodeViewAddZeroWidthSpace2);

      mentionContentWrap.appendChild(zeroWidthSpaceContainer);
      mentionContentWrap.appendChild(mentionWrap);
      mentionContentWrap.appendChild(zeroWidthSpaceContainer2);

      const selectionNode2 = suneditor.current.core.getSelectionNode();
      const selection = suneditor.current.core.getSelection();

      if (selection.focusOffset === selectionNode2.length) {
        suneditor.current.core.setRange(
          selectionNode2,
          selectionNode2.length - 1,
          selectionNode2,
          selectionNode2.length - 1,
        );
      }

      suneditor.current.core.insertNode(mentionContentWrap);

      const text = suneditor.current.core.util.createTextNode(' ');
      suneditor.current.core.insertNode(text);

      const selectionNodeAfterInsert = suneditor.current.core.getSelectionNode();
      suneditor.current.core.setRange(selectionNodeAfterInsert, 1, selectionNodeAfterInsert, 1);

      add(user.id);
      mentionedIds.current = [...mentionedIds.current, user.id];

      suneditor.current.onInput(
        new InputEvent('input', {
          bubbles: true,
          data: Array.from(document.getElementsByClassName('se-wrapper-wysiwyg'))[0].innerHTML,
        }),
      );

      showDropdownRef.current = false;
      isPremention.current = false;
      setShowDropdown(false);
      setFilteredUsers(users.sort((a, b) => a.fullName.localeCompare(b.fullName)));
    },
    [suneditor, users, disabled, add],
  );

  const onRemove = useCallback(
    (content: string) => {
      if (disabled || !suneditor.current) {
        return;
      }

      if (mentionedIds.current.length) {
        const deletedMentions: number[] = [];

        mentionedIds.current.forEach(mentionId => {
          if (!content.includes(`id="mention-${mentionId}"`)) {
            deletedMentions.push(mentionId);
            remove(mentionId);
          }
        });

        mentionedIds.current = mentionedIds.current.filter(item => !deletedMentions.includes(item));
      }
    },
    [suneditor, disabled, remove],
  );

  const onClick = useCallback(() => {
    if (disabled || !suneditor.current) {
      return;
    }

    const selection = suneditor.current.core.getSelection();
    const selectionNode = suneditor.current.core.getSelectionNode();
    const { focusOffset } = selection;

    if (selection?.focusNode?.parentNode && selection.focusNode?.parentNode.className.split(' ').includes('mention')) {
      selection.focusNode.parentNode.style.backgroundColor = colors.functionals.infoLight;
      setIsMentionSelected(true);

      if (lastSelection.current && lastSelection.current !== selection.focusNode?.parentNode) {
        lastSelection.current.style.backgroundColor = colors.grey10;
        setIsMentionSelected(false);
      }
      lastSelection.current = selection.focusNode?.parentNode;
    } else if (lastSelection.current) {
      lastSelection.current.style.backgroundColor = colors.grey10;
      lastSelection.current = null;
      setIsMentionSelected(false);
    }

    if (
      selection?.focusNode?.parentNode &&
      !selection.focusNode?.parentNode.className.split(' ').includes('pre-mention') &&
      isPremention.current
    ) {
      if (preMentionSelection.current) {
        const newNode: HTMLElement = suneditor.current.core.util.createElement('SPAN');
        newNode.innerHTML = preMentionSelection.current.innerText;
        suneditor.current.core.insertNode(newNode, preMentionSelection.current);

        suneditor.current.util.removeItem(preMentionSelection.current);

        suneditor.current.core.setRange(selectionNode, focusOffset, selectionNode, focusOffset);

        preMentionSelection.current = null;
        isPremention.current = false;
        setShowDropdown(false);
      }
    }
  }, [suneditor, disabled]);

  const renderDropdown = useCallback(
    () => (
      <MentionDropdown
        open={showDropdown && !!filteredUsers.length}
        anchorEl={preMentionSelection.current}
        users={filteredUsers}
        onSelect={handleSelectUser}
      />
    ),
    [filteredUsers, showDropdown, handleSelectUser],
  );

  return {
    filteredUsersMention: filteredUsers,
    showMentionDropdown: showDropdown,
    isMentionSelected,
    onKeyDownMention: onKeyDown,
    onKeyUpMention: onKeyUp,
    onRemoveMention: onRemove,
    onClickMention: onClick,
    renderMentionUsersDropdown: renderDropdown,
  };
};
