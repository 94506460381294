import { BrowserRouter } from "react-router-dom";
import {
  AlertProvider,
  CalendarPickerProvider,
  CollegeHunksTheme,
  DialogContextController,
  Loader,
  SplitContextController,
} from "@chhjit/react-components";
import { FC, Suspense } from "react";

import { ErrorBoundary } from "features/error-boundary";
import { ScrollToTop } from "widgets";
import { ignoreScrollToTopBetweenPaths } from "shared/utils";
import { splitAuthKey, splitKey } from "shared/config";

export const withProviders = (WrappedComponent: FC) => () =>
  (
    <CollegeHunksTheme>
      <ErrorBoundary>
        <CalendarPickerProvider>
          <AlertProvider
            maxSnack={3}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            preventDuplicate
          >
            <SplitContextController
              sdkKey={splitAuthKey}
              customerKey={splitKey}
            >
              <BrowserRouter>
                <DialogContextController>
                  <Suspense fallback={<Loader size="large" />}>
                    <ScrollToTop
                      ignoreBetweenPaths={ignoreScrollToTopBetweenPaths}
                    />
                    <WrappedComponent />
                  </Suspense>
                </DialogContextController>
              </BrowserRouter>
            </SplitContextController>
          </AlertProvider>
        </CalendarPickerProvider>
      </ErrorBoundary>
    </CollegeHunksTheme>
  );
