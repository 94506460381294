import { useCallback, useEffect, useRef, useState } from 'react';

import { ImagePluginInput } from '../ui/imagePluginInput/ImagePluginInput';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  suneditor: any;
};
export const useImage = ({ suneditor }: Props) => {
  const imagePluginInputRef = useRef<HTMLInputElement>(null);

  const [isImagePluginInputShown, setIsImagePluginInputShown] = useState(false);

  const handleChangeImage = useCallback(
    (files: FileList) => {
      if (suneditor && files.length) {
        suneditor.insertImage(files);
      }
    },
    [suneditor],
  );

  useEffect(() => {
    const interval = setInterval(() => {
      if (imagePluginInputRef.current) {
        setIsImagePluginInputShown(true);
        clearInterval(interval);
      }
    }, 10);
  }, []);

  const renderPlugin = useCallback(
    () => <ImagePluginInput ref={imagePluginInputRef} onChange={handleChangeImage} />,
    [handleChangeImage],
  );

  return {
    imagePluginInputRef,
    isImagePluginInputShown,
    renderImagePlugin: renderPlugin,
  };
};
