import { memo, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { colors, SidenavMenu } from "@chhjit/react-components";
import { useStore } from "effector-react";
import moment from "moment";

import { apiDateFormat, apiDateTimeFormat, routePaths } from "shared/utils";
import { $notifications } from "entities/notifications";
import { $userTasks } from "entities/tasks";
import { useLocationTimezone } from "shared/hooks";
import { SwimlaneTypesEnum } from "entities/swimlanes";
import { $auth } from "features/auth";

import { useStyles } from "./styles";
import { NavMenuProps } from "./types";

export const NavMenu = memo(
  ({ isTrainingModeOn, onMenuItemSelect, logout }: NavMenuProps) => {
    const styles = useStyles();

    const location = useLocation();
    const navigate = useNavigate();

    const { locationId } = useStore($auth);
    const { filteredNotifications } = useStore($notifications);
    const { tasks } = useStore($userTasks);

    const { utcToZone } = useLocationTimezone("location");

    const filteredTasks = useMemo(() => {
      const currentDate = utcToZone(
        moment.utc().format(apiDateTimeFormat),
        apiDateFormat,
      );

      return tasks.filter(
        (task) =>
          moment(task.due.datetime).format(apiDateFormat) === currentDate &&
          Number(task.locationId) === locationId &&
          task.swimlane.type.id !== SwimlaneTypesEnum.Done &&
          !task.isDeleted &&
          !task.board.isDeleted,
      );
    }, [tasks, locationId, utcToZone]);

    const countOverduedTasks = useMemo(
      () => filteredTasks.filter((task) => task.isOverdue).length,
      [filteredTasks],
    );

    const handleMenuItemClick = useCallback(
      (path: string) => {
        if (location.pathname !== path) {
          navigate(path);
        }

        onMenuItemSelect();
      },
      [location.pathname, onMenuItemSelect, navigate],
    );

    return (
      <div className={styles.sideNavMenuContainer}>
        <SidenavMenu
          menuItems={[
            {
              title: "Dashboard",
              isActive: location.pathname.includes(routePaths.dashboard()),
              onClick: () => handleMenuItemClick(routePaths.dashboard()),
            },
            {
              title: "Notifications",
              isActive: location.pathname.includes(routePaths.notifications()),
              badge: {
                type: "primary",
                content: String(filteredNotifications.length),
                visible: filteredNotifications.length !== 0,
              },
              onClick: () => handleMenuItemClick(routePaths.notifications()),
            },
            {
              title: "Leaderboards",
              isActive: location.pathname.includes(routePaths.leaderboards()),
              onClick: () => handleMenuItemClick(routePaths.leaderboards()),
            },
            {
              title: "Tasks",
              isActive: location.pathname.includes(routePaths.tasksList()),
              badge: {
                type: "primary",
                content: String(countOverduedTasks),
                visible: countOverduedTasks !== 0,
              },
              separator: true,
              onClick: () => handleMenuItemClick(routePaths.tasksList()),
            },
            {
              title: "User profile",
              isActive: location.pathname.includes(routePaths.userProfile()),
              onClick: () => handleMenuItemClick(routePaths.userProfile()),
            },
            {
              title: "Settings",
              isActive: location.pathname.includes(routePaths.settings()),
              onClick: () => handleMenuItemClick(routePaths.settings()),
            },
            {
              title: "Training",
              isActive: location.pathname.includes(routePaths.training()),
              onClick: () => handleMenuItemClick(routePaths.training()),
            },
            {
              title: "Training mode: OFF",
              isActive: false,
              element: (
                <>
                  Training mode:{" "}
                  <span
                    style={{
                      color: isTrainingModeOn
                        ? colors.complementary.red.shade02
                        : colors.grey140,
                    }}
                  >
                    {isTrainingModeOn ? "On" : "Off"}
                  </span>
                </>
              ),
              onClick: () => handleMenuItemClick(routePaths.settings()),
            },
            {
              title: "Report an issue",
              element: (
                <a
                  style={{
                    width: "100%",
                    display: "block",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                  href="mailto:hunkhelp@chhj.com?subject=Crew app issue reporting"
                >
                  Report an issue
                </a>
              ),
            },
            {
              title: "Log out",
              onClick: () => logout(),
            },
          ]}
        />
      </div>
    );
  },
);
