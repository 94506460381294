import { RefObject, useMemo } from 'react';

import CodeMirror from 'codemirror';
import { image, link } from 'suneditor/src/plugins';

import { imagePlugin } from '../plugins/imagePlugin';
import { TextEditorProps } from '../TextEditor.types';
import { editorDefaultOptions } from '../utils/editorDefaultOptions';

type Props = Pick<TextEditorProps, 'plugins' | 'imageSupport' | 'options'> & {
  imagePluginInputRef: RefObject<HTMLInputElement>;
};

export const useOptions = ({ plugins, imageSupport, options, imagePluginInputRef }: Props) => {
  const combinedPlugins = useMemo(() => {
    const combined = plugins || [link];

    if (imageSupport) {
      combined.push(imagePlugin(imagePluginInputRef), image);
    }

    return combined;
  }, [imageSupport, plugins, imagePluginInputRef]);

  const editorOptions = useMemo(
    () => ({
      ...editorDefaultOptions({
        plugins: combinedPlugins,
      }),
      imageResizing: !!imageSupport,
      imageHeightShow: !!imageSupport,
      imageFileInput: !!imageSupport,
      imageUrlInput: !!imageSupport,
      ...options,
      codeMirror: CodeMirror,
    }),
    [options, imageSupport, combinedPlugins],
  );

  return { editorOptions };
};
