import { colors } from "@chhjit/react-components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  avatarMobile: {
    height: 66,
    width: 66,
  },
  avatarDesktop: {
    height: 100,
    width: 100,
  },
  avatarApproved: {
    color: colors.functionals.alert,
  },
  avatarBadge: {
    width: 32,
    height: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100px",
    position: "relative",
    top: "-14px",
    right: "7.8px",
    backgroundColor: colors.white,
  },
}));
