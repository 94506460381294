import { colors } from "@chhjit/react-components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    padding: "10px 8px",
    textAlign: "center",
    backgroundColor: colors.complementary.red.shade02,
    color: colors.basic.white,

    [theme.breakpoints.down("md")]: {
      padding: "4px 8px",
    },
  },
}));
