import { memo } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@chhjit/react-components";

import { MissingPropertyIcon } from "shared/assets";
import { routePaths } from "shared/utils";

export const NotFoundPage = memo(() => {
  const navigate = useNavigate();

  return (
    <Box
      flex={1}
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      px={3}
      py={4}
    >
      <Box mb={2}>
        <MissingPropertyIcon fontSize={144} />
      </Box>

      <Box mb={1}>
        <Typography variant="h1" color="secondary" align="center">
          Not Found
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h4" color="primary" align="center">
          The page you are looking for does not exist or has been deleted
        </Typography>
      </Box>

      <Box mb={15}>
        <Grid container spacing={2} justify="center">
          <Grid item>
            <Box width={128}>
              <Link
                to={routePaths.dashboard()}
                style={{ textDecoration: "none" }}
              >
                <Button buttonType="outlined" fullWidth>
                  Go to home page
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item>
            <Box width={128}>
              <Button
                buttonType="twoTone"
                fullWidth
                onClick={() => navigate(-1)}
              >
                Go back
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});
