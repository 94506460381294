import { FC, forwardRef, memo, useCallback } from 'react';

import clsx from 'clsx';
import SunEditor from 'suneditor-react';

import 'suneditor/dist/css/suneditor.min.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/htmlmixed/htmlmixed';

import { useImage } from './hooks/useImage';
import { useInstance } from './hooks/useInstance';
import { useMention } from './hooks/useMention';
import { useOptions } from './hooks/useOptions';
import { useStyles } from './TextEditor.styles';
import { TextEditorProps } from './TextEditor.types';

export const TextEditor: FC<TextEditorProps> = memo(
  forwardRef(
    ({
      value = '',
      options,
      plugins,
      imageSupport,
      mentionProps,
      height,
      error,
      disabled,
      containerClassName,
      onChange: onChangeType,
      onInputType,
      onImageUploadBefore,
      toggleCodeView,
      getInstance,
    }) => {
      const { suneditor, getSunEditorInstance } = useInstance({ getInstance });
      const { imagePluginInputRef, isImagePluginInputShown, renderImagePlugin } = useImage({
        suneditor: suneditor.current,
      });
      const {
        showMentionDropdown,
        isMentionSelected,
        onKeyDownMention,
        onKeyUpMention,
        onClickMention,
        onRemoveMention,
        renderMentionUsersDropdown,
      } = useMention({
        suneditor: suneditor,
        users: mentionProps?.users ?? [],
        mentionesId: mentionProps?.mentionesId,
        disabled: !mentionProps,
        add: mentionProps?.add ?? (() => {}),
        remove: mentionProps?.remove ?? (() => {}),
      });
      const { editorOptions } = useOptions({ plugins, imageSupport, options, imagePluginInputRef });

      const styles = useStyles({
        disableActions: showMentionDropdown || isMentionSelected,
        disabled: !!disabled,
      });

      const handleChange = useCallback(
        (content: string) => {
          if (onChangeType) {
            onRemoveMention(content);

            onChangeType(content.replace(/\u200B/g, ''));
          }
        },
        [onRemoveMention, onChangeType],
      );

      const handleInput = useCallback(
        e => {
          if (onInputType) {
            const content: string = e.target?.innerHTML;

            if (content) {
              onRemoveMention(content);

              onInputType(content.replace(/\u200B/g, ''));
            }
          }
        },
        [onRemoveMention, onInputType],
      );

      const handleKeyDown = useCallback(
        (e: KeyboardEvent) => {
          onKeyDownMention(e);
        },
        [onKeyDownMention],
      );

      const handleKeyUp = useCallback(
        (e: KeyboardEvent) => {
          onKeyUpMention(e);
        },
        [onKeyUpMention],
      );

      const handleClick = useCallback(() => {
        onClickMention();
      }, [onClickMention]);

      return (
        <div className={styles.root}>
          {renderImagePlugin()}

          <div className={clsx(styles.editorWrapper, containerClassName, error && styles.error)}>
            {isImagePluginInputShown && (
              <SunEditor
                defaultValue={value}
                setContents={value}
                setOptions={editorOptions}
                height={height}
                disable={disabled}
                onChange={handleChange}
                onInput={handleInput}
                onKeyDown={handleKeyDown}
                onKeyUp={handleKeyUp}
                onClick={handleClick}
                getSunEditorInstance={getSunEditorInstance}
                onImageUploadBefore={onImageUploadBefore}
                toggleCodeView={toggleCodeView}
              />
            )}
          </div>

          {renderMentionUsersDropdown()}
        </div>
      );
    },
  ),
);
