import { memo } from 'react';

import Popper from '@material-ui/core/Popper';

import { Paper } from 'components/paper/Paper';

import { useStyles } from './MentionDropdown.styles';
import { MentionDropdownProps } from './MentionDropdown.types';
import { UsersList } from './usersList/UsersList';

export const MentionDropdown = memo(({ open, users, anchorEl, onSelect }: MentionDropdownProps) => {
  const styles = useStyles();

  return (
    <Popper anchorEl={anchorEl} open={open} placement="bottom-start" className={styles.popper}>
      <Paper elevation={16} className={styles.content}>
        <UsersList users={users} onUserSelect={onSelect} />
      </Paper>
    </Popper>
  );
});
