import { FC, memo, useEffect } from "react";
import { useStore } from "effector-react";
import { colors } from "@chhjit/react-components";
import clsx from "clsx";

import { $authorizedUser } from "features/auth";
import { SidebarNav } from "widgets/sidebar-nav";
import { useThemeColor } from "shared/hooks";
import { useSidebarState } from "features/sidebar";
import { useSideNavState } from "features/sidenav";

import { useStyles } from "./styles";
import { PageLayoutProps } from "./types";

export const PageLayout: FC<PageLayoutProps> = memo(({ children, logout }) => {
  const styles = useStyles();

  const { user } = useStore($authorizedUser);
  const { showSideNav } = useSideNavState();
  const { showSidebar, sidebarContent, sidebarClassName } = useSidebarState();

  const { changeThemeColor } = useThemeColor();

  useEffect(() => {
    if (showSideNav) {
      changeThemeColor(colors.white);
    } else {
      changeThemeColor(colors.grey10);
    }
  }, [showSideNav, changeThemeColor]);

  return (
    <div className={styles.root}>
      {showSideNav && (
        <div className={styles.sidebarNavContainer}>
          <SidebarNav user={user} logout={logout} />
        </div>
      )}

      {showSidebar && (
        <div className={clsx(styles.sidebarContainer, sidebarClassName)}>
          {sidebarContent}
        </div>
      )}

      <div className={styles.content}>{children}</div>
    </div>
  );
});
