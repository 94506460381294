import { Loader, useDialogDispatch } from "@chhjit/react-components";
import { memo, useEffect } from "react";

import { useStyles } from "./assets";

type Props = {
  isDialog?: boolean;
};

export const PageLoading = memo(({ isDialog }: Props) => {
  const styles = useStyles();

  const setDialog = useDialogDispatch();

  useEffect(() => {
    if (isDialog) {
      setDialog({ open: true, variant: "basic" });
    }
  }, [isDialog]);

  if (isDialog) {
    return <></>;
  }

  return (
    <div className={styles.root}>
      <Loader size="large" />
    </div>
  );
});
