import "shared/assets/styles/main.scss";
import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { useStore } from "effector-react";
import { matchPath, useLocation } from "react-router-dom";
import { useSplit } from "@chhjit/react-components";

import {
  $authorizedUser,
  $location,
  getLocationFx,
  getUserFx,
  useAuth,
} from "features/auth";
import { useErrorBoundary } from "features/error-boundary/model";
import { SidebarController } from "features/sidebar";
import {
  $developments,
  addBillableTimeTravelledEv,
  addBillableTimeWorkedEv,
  addTimeTravelledEv,
  addTimeWorkedEv,
  ProcessStatusEnum,
  resetWorkAndTravelInfo,
} from "features/developments";
import { useTimeTrack } from "shared/hooks";
import { getPermissionsFx } from "features/permissions";
import {
  $appSettings,
  $settings,
  SettingItemsEnum,
  getAppSettingsFx,
  getSettingValue,
  paymentPlatformOptions,
  setSettingEv,
  setSettingValue,
} from "features/settings";
import { isHunkPayEnabled } from "shared/config";
import { setOfflineRequestsEv } from "features/job-offline-changes";
import { getNotificationsFx } from "entities/notifications";
import { addendumRoutePaths } from "shared/utils";
import {
  setInitialAddendumAppointment,
  setInitialAddendumProductItems,
} from "entities/addendum";
import { getUserTasksFx } from "entities/tasks";
import { PageLayout } from "shared/templates";
import { SideNavController } from "features/sidenav";
import { PaymentProviderEnum } from "entities/payments";

import { withProviders } from "./model/utils";
import { AppRouting } from "./features";
import { useStyles } from "./assets";

const App = memo(() => {
  const styles = useStyles();

  const { pathname } = useLocation();

  const { location } = useStore($location);
  const { user } = useStore($authorizedUser);
  const { paymentPlatform } = useStore($settings);
  const { appSettings, loading: appSettingsLoading } = useStore($appSettings);
  const {
    travelStatus,
    timeTravelled,
    billableTimeTravelled,
    workStatus,
    timeWorked,
    billableTimeWorked,
  } = useStore($developments);

  const notificationsInterval = useRef<ReturnType<typeof setInterval> | null>(
    null,
  );

  useErrorBoundary();
  const setSplitAttributes = useSplit();
  const { isAuthorized, isAuthorizing, locationId, logout } = useAuth();

  const isAddendumPage = useMemo(
    () => !!addendumRoutePaths.find((path) => matchPath(path, pathname)),
    [pathname],
  );

  const fetchNotifications = useCallback(() => {
    if (locationId && user?.id) {
      getNotificationsFx({ locationId, userId: user.id });
    }
  }, [locationId, user?.id]);

  const onChangeTravelTime = useCallback(
    (seconds: number) => addTimeTravelledEv({ seconds }),
    [],
  );

  const onChangeBillableTravelTime = useCallback(
    (seconds: number) => addBillableTimeTravelledEv({ seconds }),
    [],
  );

  const onChangeWorkTime = useCallback(
    (seconds: number) => addTimeWorkedEv({ seconds }),
    [],
  );

  const onChangeBillableWorkTime = useCallback(
    (seconds: number) => addBillableTimeWorkedEv({ seconds }),
    [],
  );

  const windowFocusListener = useCallback(() => resetWorkAndTravelInfo(), []);

  useTimeTrack({
    time: timeTravelled,
    isRunning: travelStatus === ProcessStatusEnum.InProgress,
    onChangeTime: onChangeTravelTime,
  });

  useTimeTrack({
    time: billableTimeTravelled,
    isRunning: travelStatus === ProcessStatusEnum.InProgress,
    onChangeTime: onChangeBillableTravelTime,
  });

  useTimeTrack({
    time: timeWorked,
    isRunning: workStatus === ProcessStatusEnum.InProgress,
    onChangeTime: onChangeWorkTime,
  });

  useTimeTrack({
    time: billableTimeWorked,
    isRunning: workStatus === ProcessStatusEnum.InProgress,
    onChangeTime: onChangeBillableWorkTime,
  });

  useEffect(() => {
    window.addEventListener("focus", windowFocusListener);

    return () => {
      window.removeEventListener("focus", windowFocusListener);
    };
  }, []);

  // Getting User Info With Token And Permissions
  useEffect(() => {
    if (isAuthorized) {
      getUserFx();
      getPermissionsFx();
    }
  }, [isAuthorized]);

  // Getting Location Info And App Settings
  useEffect(() => {
    if (isAuthorized && !isAuthorizing && locationId) {
      getLocationFx({
        locationId,
      });
      getAppSettingsFx({
        locationId,
      });
    }
  }, [isAuthorized, isAuthorizing, locationId]);

  useEffect(() => {
    fetchNotifications();

    if (!notificationsInterval.current) {
      notificationsInterval.current = setInterval(fetchNotifications, 60000);
    }

    return () => {
      if (notificationsInterval.current) {
        clearInterval(notificationsInterval.current);
        notificationsInterval.current = null;
      }
    };
  }, [fetchNotifications]);

  useEffect(() => {
    if (user?.id) {
      getUserTasksFx({ userId: user.id });
    }
  }, [user?.id]);

  useEffect(() => {
    if (!location?.id) {
      return;
    }

    const isSquareValid =
      location.integrations.square.active && location.integrations.square.valid;
    const isHunkPayValid =
      location.integrations.hunkpay.valid && isHunkPayEnabled;

    const shouldChangePaymentPlatformToDefault =
      (paymentPlatform.value === PaymentProviderEnum.Square &&
        !isSquareValid) ||
      (paymentPlatform.value === PaymentProviderEnum.HunkPay &&
        !isHunkPayValid);

    if (shouldChangePaymentPlatformToDefault) {
      setSettingEv({
        name: "paymentPlatform",
        value: paymentPlatformOptions[0],
      });
      setSettingValue(
        SettingItemsEnum.PaymentPlatform,
        paymentPlatformOptions[0],
      );
    }
  }, [
    location?.id,
    location?.integrations.square.active,
    location?.integrations.square.valid,
    location?.integrations.hunkpay.valid,
    paymentPlatform.value,
  ]);

  useEffect(() => {
    if (appSettingsLoading || !locationId || !appSettings) {
      return;
    }

    const preJobInspection = getSettingValue<boolean>(
      SettingItemsEnum.PreJobInspection,
    );
    const postJobInspection = getSettingValue<boolean>(
      SettingItemsEnum.PostJobInspection,
    );

    setSettingEv({
      name: "preJobInspection",
      value: preJobInspection ?? !!appSettings.preInspection.showStep,
    });
    setSettingEv({
      name: "postJobInspetion",
      value: postJobInspection ?? !!appSettings.postInspection.showStep,
    });
  }, [appSettingsLoading, appSettings, locationId]);

  useEffect(() => {
    if (!isAddendumPage) {
      setInitialAddendumAppointment(null);
      setInitialAddendumProductItems([]);
    }
  }, [isAddendumPage]);

  useEffect(() => {
    if (user?.id) {
      setOfflineRequestsEv();
    }
  }, [user?.id]);

  useEffect(() => {
    setSplitAttributes({
      user_id: user?.id!,
      user_username: user?.userName!,
      location_id: location?.id!,
      location_name: location?.name!,
      location_state: location?.address.state!,
      location_timezone_name: location?.timeZone.name!,
      group_name: user?.group.name!,
    });
  }, [
    location?.address.state,
    location?.id,
    location?.name,
    location?.timeZone.name,
    user?.group.name,
    user?.id,
    user?.userName,
  ]);

  return (
    <div className={styles.root}>
      <SideNavController>
        <SidebarController>
          <PageLayout logout={logout}>
            <AppRouting />
          </PageLayout>
        </SidebarController>
      </SideNavController>
    </div>
  );
});

export default withProviders(App);
