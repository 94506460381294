import { SunEditorOptions } from 'suneditor/src/options';
import {
  align,
  blockquote,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  math,
  paragraphStyle,
  table,
  template,
  textStyle,
} from 'suneditor/src/plugins';

import { TextEditorProps } from '../TextEditor.types';
import { NoahFont } from 'assets';

import { toolbarIcons } from './toolbarIcons';

type Props = Pick<TextEditorProps, 'plugins'>;

export const editorDefaultOptions = ({ plugins }: Props): SunEditorOptions => {
  let combinedPlugins = [
    align,
    blockquote,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    lineHeight,
    list,
    math,
    paragraphStyle,
    table,
    template,
    textStyle,
  ];

  if (plugins) {
    combinedPlugins = combinedPlugins.concat(plugins);
  }

  return {
    placeholder: 'Type here ...',
    defaultStyle: `font-size: 16px; font-family: ${NoahFont}; fontWeight: bold; color: #353535;`,
    fontSize: [8, 10, 12, 14, 16, 18, 20, 24, 36],
    plugins: combinedPlugins,
    videoResizing: false,
    videoHeightShow: false,
    videoFileInput: false,
    videoUrlInput: false,
    videoRatioShow: false,
    audioUrlInput: false,
    buttonList: [
      ['bold', 'italic', 'strike', 'underline', 'removeFormat'],
      ['align', 'list', 'blockquote', 'hiliteColor', 'fontColor'],
      ['formatBlock'],
      ['fontSize'],
      ['undo', 'redo', 'fullScreen'],

      [
        '%899',
        [
          ['bold', 'italic', 'strike', 'underline', 'removeFormat'],
          ['fontSize'],
          '/',
          ['align', 'list', 'blockquote', 'hiliteColor', 'fontColor'],
          ['formatBlock'],
          ['undo', 'redo', 'fullScreen'],
        ],
      ],

      [
        '%599',
        [
          ['bold', 'italic', 'strike', 'underline', 'removeFormat'],
          ['undo', 'redo'],
          '/',
          ['align', 'list', 'blockquote', 'hiliteColor', 'fontColor'],
          '/',
          ['formatBlock'],
          ['fontSize'],
          ['fullScreen'],
        ],
      ],
    ],
    icons: Object.keys(toolbarIcons).reduce(
      (acc, current) => ({ ...acc, [current]: `<div class="toolbar-icon">${toolbarIcons[current]}</div>` }),
      {},
    ),
    showPathLabel: false,
    height: '180px',
  };
};
