import { colors } from "@chhjit/react-components";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("lg")]: {
      width: 300,
      height: "100%",
    },
  },
  content: {
    backgroundColor: colors.basic.white,
    display: "flex",
    alignItems: "center",
    minHeight: 40,
    padding: "4px 16px",
    gap: 16,
  },
  contentWithPageName: {
    minHeight: 70,
  },
  leftSection: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    gap: 24,
  },
  centerSection: {
    width: "fit-content",
  },
  rightSection: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 16,
  },
  swipeableDrawerPaper: {
    maxWidth: 300,
    width: "100%",
  },
  badge: {
    minWidth: 13,
    height: 13,
    padding: "0px 2px",
    borderRadius: 6.5,
  },
  badgeText: {
    fontSize: 10,
    lineHeight: "11px",
    color: colors.white,
  },
}));
