import { memo, useCallback, useMemo } from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';
import { List, ListRowProps } from 'react-virtualized/dist/es/List';

import { UserIdentifier } from 'components/userIdentifier/UserIdentifier';

import { OPTION_ITEM_HEIGHT, OPTION_ITEM_HEIGHT_MOBILE, useStyles } from './UsersList.styles';
import { UsersListProps } from './UsersList.types';

export const UsersList = memo(({ users, onUserSelect }: UsersListProps) => {
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const rowHeight = useMemo(() => (isMobile ? OPTION_ITEM_HEIGHT_MOBILE : OPTION_ITEM_HEIGHT), [isMobile]);

  const itemsTotalHeight = useMemo(() => (users.length > 5 ? 165 : users.length * OPTION_ITEM_HEIGHT), [users.length]);

  const getRowRender = useCallback(
    ({ key, index, style }: ListRowProps) => {
      const user = users[index];

      return (
        <MenuItem
          key={key}
          style={style}
          className={clsx(styles.optionItem, index % 2 === 0 ? styles.optionItemEven : styles.optionItemOdd)}
          onClick={() => onUserSelect(user)}
        >
          <UserIdentifier
            name={user.fullName}
            imageUrl={user.imageUrl}
            size="small"
            classes={{
              rootContainer: styles.optionRootContainer,
              usernameContainer: styles.optionUsernameContainer,
              username: styles.optionUsername,
            }}
          />
        </MenuItem>
      );
    },
    [users, onUserSelect],
  );

  return (
    <List
      height={itemsTotalHeight}
      width={200}
      rowHeight={rowHeight}
      rowRenderer={getRowRender}
      rowCount={users.length}
      overscanRowCount={5}
    />
  );
});
