import { makeStyles } from '@material-ui/core';

import { colors } from 'assets';

export const useStyles = makeStyles(() => ({
  popper: {
    zIndex: 1301,
  },
  content: {
    border: `0.5px solid ${colors.grey60}`,
    borderRadius: 4,
    overflow: 'hidden',
  },
}));
